import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Form,
  Input,
  Button,
  Space,
  Drawer,
  Upload,
  DatePicker,
  Modal,
  Divider,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { toast } from "react-hot-toast";
import {
  saveSeminarAlbum,
  updateSeminarAlbum,
} from "../../../store/seminarAlbum/seminarAlbumSlice";
import { BASE_DOC_URL } from "../../../utils/actionTypes";
import { MdDelete } from "react-icons/md";
import "./style.css";

const CreateSeminarAlbum = ({
  onClose,
  open,
  data,
  isAdd,
  isView,
  permission,
  onAlbumChange,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [imageList, setImageList] = useState(() => {
    if (data?.images) {
      return data.images.map((image, index) => ({
        uid: `-${index}`,
        name: image.title || `Image ${index + 1}`,
        status: "done",
        url: `${BASE_DOC_URL}/${image.image_path}`,
        existing: true,
        imageId: image.id,
        title: image.title || "",
        description: image.description || "",
        captureTime: image.capture_time ? moment(image.capture_time) : null,
      }));
    }
    return [];
  });
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState(() => {
    return data?.thumbnail ? `${BASE_DOC_URL}/${data.thumbnail}` : null;
  });

  const handleSaveClick = (e) => {
    e.preventDefault();
    form.submit();
  };

  const handleImagesChange = ({ fileList }) => {
    const updatedFileList = fileList.map(file => {
      if (file.existing) {
        return file;
      }
      return {
        ...file,
        title: file.title || '',
        description: file.description || '',
        captureTime: file.captureTime || null,
        isNew: true
      };
    });
    setImageList(updatedFileList);
  };

  const onFinish = async (values) => {
    try {
      const formData = new FormData();

      const formValues = {
        ...values,
        thumbnail: undefined,
      };
      formData.append("data", JSON.stringify(formValues));

      const thumbnailFile = form.getFieldValue("thumbnail");
      if (thumbnailFile) {
        formData.append("thumbnail", thumbnailFile);
      }

      if (!thumbnailUrl && !thumbnailFile) {
        formData.append("removeThumbnail", "true");
      }

      if (imageList.length > 0) {
        const existingImages = imageList
          .filter((img) => img.existing)
          .map((img) => ({
            id: img.imageId,
            title: img.title,
            description: img.description,
            capture_time: img.captureTime ? img.captureTime.toISOString() : null,
          }));
        formData.append("existingImages", JSON.stringify(existingImages));

        const newImages = imageList.filter((img) => !img.existing);
        newImages.forEach((image, index) => {
          formData.append("images", image.originFileObj);
          
          const metadata = {
            title: image.title || null,
            description: image.description || null,
            captureTime: image.captureTime ? moment(image.captureTime).toISOString() : null,
          };
          formData.append("imageMeta", JSON.stringify(metadata));
        });
      }

      for (let pair of formData.entries()) {
        console.log(pair[0], pair[1]);
      }

      if (isAdd) {
        await dispatch(saveSeminarAlbum(formData)).then((response) => {
          if (response?.payload?.statusCode === 201) {
            toast.success(response?.payload?.message, { duration: 3000 });
            form.resetFields();
            onAlbumChange();
          }
        });
      } else {
        await dispatch(updateSeminarAlbum({ id: data.id, formData })).then(
          (response) => {
            if (response?.payload?.statusCode === 200) {
              toast.success(response?.payload?.message, { duration: 3000 });
              onAlbumChange();
            }
          }
        );
      }
      onClose();
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to save seminar album", { duration: 3000 });
    }
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleCancel = () => setPreviewOpen(false);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleImageRemove = (file) => {
    if (file.existing) {
      Modal.confirm({
        title: "Are you sure you want to remove this image?",
        content: "This action cannot be undone.",
        okText: "Yes",
        cancelText: "No",
        onOk: () => {
          setImageList((prev) => prev.filter((item) => item.uid !== file.uid));
        },
      });
      return false;
    }
    return true;
  };

  const handleThumbnailChange = (info) => {
    if (info.file.status === "removed") {
      setThumbnailUrl(null);
      form.setFieldValue("thumbnail", null);
      return;
    }

    const file = info.file.originFileObj || info.file;
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setThumbnailUrl(previewUrl);
      form.setFieldValue("thumbnail", file);
    }
  };

  useEffect(() => {
    return () => {
      if (thumbnailUrl && thumbnailUrl.startsWith("blob:")) {
        URL.revokeObjectURL(thumbnailUrl);
      }
    };
  }, [thumbnailUrl]);

  const customItemRender = (originNode, file) => {
    return (
      <div className="upload-list-item" style={{ width: "100%" }}>
        <div className="image-preview" style={{ marginBottom: "8px" }}>
          {originNode}
        </div>
        <div className="image-metadata" style={{ width: "100%" }}>
          <Input
            className="mb-3"
            placeholder="Title"
            value={file.title}
            onChange={(e) => {
              const newList = imageList.map((item) => {
                if (item.uid === file.uid) {
                  return { ...item, title: e.target.value };
                }
                return item;
              });
              setImageList(newList);
            }}
          />
          <Input.TextArea
            className="mb-3"
            placeholder="Description"
            value={file.description}
            onChange={(e) => {
              const newList = imageList.map((item) => {
                if (item.uid === file.uid) {
                  return { ...item, description: e.target.value };
                }
                return item;
              });
              setImageList(newList);
            }}
          />
          <DatePicker
            className="mb-3 w-full"
            showTime
            placeholder="Capture Time"
            value={file.captureTime}
            onChange={(date) => {
              const newList = imageList.map((item) => {
                if (item.uid === file.uid) {
                  return { ...item, captureTime: date };
                }
                return item;
              });
              setImageList(newList);
            }}
          />
        </div>
        <Divider />
      </div>
    );
  };

  return (
    <Drawer
      title={`${isAdd ? "Add" : "Edit"} Seminar Album`}
      placement="right"
      width={"100%"}
      onClose={onClose}
      open={open}
      maskClosable={false}
      extra={
        <Space>
          {permission &&
            (permission.can_create || permission.can_update) &&
            !isView && (
              <Button type="primary" onClick={handleSaveClick}>
                Save
              </Button>
            )}
        </Space>
      }
    >
      <Form
        disabled={isView}
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          title: data?.title || "",
          subtitle: data?.subtitle || "",
          description: data?.description || "",
          location: data?.location || "",
          seminarDate: data?.seminarDate ? moment(data.seminarDate) : null,
        }}
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: "Please enter title" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="subtitle" label="Subtitle">
          <Input />
        </Form.Item>

        <Form.Item name="description" label="Description">
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item name="location" label="Location">
          <Input />
        </Form.Item>

        <Form.Item
          name="seminarDate"
          label="Seminar Date"
          rules={[{ required: true, message: "Please select seminar date" }]}
        >
          <DatePicker className="w-full" />
        </Form.Item>

        <Form.Item name="thumbnail" label="Thumbnail">
          <Upload
            listType="picture-card"
            maxCount={1}
            beforeUpload={() => false}
            onChange={handleThumbnailChange}
            onPreview={handlePreview}
            showUploadList={false}
          >
            {thumbnailUrl ? (
              <div style={{ position: "relative" }}>
                <img
                  src={thumbnailUrl}
                  alt="thumbnail"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
                {!isView && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background: "rgba(0,0,0,0.5)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      opacity: 0,
                      transition: "opacity 0.3s",
                      cursor: "pointer",
                    }}
                    className="hover:opacity-100"
                  >
                    <PlusOutlined
                      style={{ color: "white", fontSize: "20px" }}
                    />
                  </div>
                )}
              </div>
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        {thumbnailUrl && !isView && (
          <Button
            type="text"
            danger
            onClick={() => {
              setThumbnailUrl(null);
              form.setFieldValue("thumbnail", null);
            }}
            icon={<MdDelete />}
          >
            Remove Thumbnail
          </Button>
        )}

        <Divider>Gallery Images</Divider>

        <Upload
          listType="picture-card"
          fileList={imageList}
          beforeUpload={() => false}
          onChange={handleImagesChange}
          onPreview={handlePreview}
          //   onRemove={handleImageRemove}
          multiple={true}
          accept="image/*"
          showUploadList={false}
          customRequest={({ file, onSuccess }) => {
            setTimeout(() => {
              onSuccess("ok");
            }, 0);
          }}
          className="image-uploader-container"
          style={{ width: "100%" }}
        >
          {uploadButton}
        </Upload>

        <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
          {imageList.map((file) => (
            <div key={file.uid} className="upload-list-item relative">
              <div
                className="absolute top-0 right-0"
                onClick={() => handleImageRemove(file)}
              >
                <MdDelete className="text-red-500 cursor-pointer text-2xl" />
              </div>
              <div className="image-preview">
                <img
                  src={
                    file.url ||
                    (file.originFileObj &&
                      URL.createObjectURL(file.originFileObj))
                  }
                  alt={file.name}
                  style={{
                    aspectRatio: "1/1",
                    maxWidth: "300px",
                    borderRadius: "4px",
                  }}
                />
              </div>
              <div className="image-metadata">
                <Input
                  className="mb-3"
                  placeholder="Title"
                  value={file.title || ''}
                  onChange={(e) => {
                    const newList = imageList.map((item) => {
                      if (item.uid === file.uid) {
                        return { ...item, title: e.target.value };
                      }
                      return item;
                    });
                    setImageList(newList);
                  }}
                />
                <Input.TextArea
                  className="mb-3"
                  placeholder="Description"
                  value={file.description || ''}
                  onChange={(e) => {
                    const newList = imageList.map((item) => {
                      if (item.uid === file.uid) {
                        return { ...item, description: e.target.value };
                      }
                      return item;
                    });
                    setImageList(newList);
                  }}
                />
                <DatePicker
                  className="mb-3 w-full"
                  showTime={false}
                  placeholder="Capture Date"
                  value={file.captureTime ? moment(file.captureTime) : null}
                  onChange={(date) => {
                    const newList = imageList.map((item) => {
                      if (item.uid === file.uid) {
                        return { ...item, captureTime: date };
                      }
                      return item;
                    });
                    setImageList(newList);
                  }}
                />
              </div>
              <Divider />
            </div>
          ))}
        </div>

        <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="preview" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </Form>
    </Drawer>
  );
};

// Utility function for image preview
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default CreateSeminarAlbum;
