import React from "react";
import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";

const PermissionDenied = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you don't have permission to access this page."
      extra={
        <Button type="primary" onClick={() => navigate("/login")}>
          Back to Login
        </Button>
      }
    />
  );
};

export default PermissionDenied;
