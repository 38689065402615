import { configureStore } from "@reduxjs/toolkit";
import themeReducer from "./theme/themeSlice";
import authReducer from "./auth/authSlice";
import loadingReducer from "./loader/loadingSlice";
import companyReducer from "./company/companySlice";
import menuReducer from "./menu/menuSlice";
import seminarAlbumReducer from "./seminarAlbum/seminarAlbumSlice";

import logger from "redux-logger";

const middlewares = [];

// Add redux-logger only in development mode
if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

const store = configureStore({
  reducer: {
    theme: themeReducer,
    auth: authReducer,
    loading: loadingReducer,
    company: companyReducer,
    menu: menuReducer,
    seminarAlbum: seminarAlbumReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewares),
});

export default store;
