import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/api";
import { PAGINATION_API, SEMINAR_ALBUM_CONTROLLER } from "../../utils/actionTypes";


// Async thunks
export const getSeminarAlbumsWithPagination = createAsyncThunk(
  "seminarAlbum/getSeminarAlbumsWithPagination",
  async (params, { rejectWithValue }) => {
    try {
      const response = await apiService.post(
        `/${SEMINAR_ALBUM_CONTROLLER}/${PAGINATION_API}`,
        params
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveSeminarAlbum = createAsyncThunk(
  "seminarAlbum/saveSeminarAlbum",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await apiService.post(
        `/${SEMINAR_ALBUM_CONTROLLER}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateSeminarAlbum = createAsyncThunk(
  "seminarAlbum/updateSeminarAlbum",
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      const response = await apiService.put(
        `/${SEMINAR_ALBUM_CONTROLLER}/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSeminarAlbum = createAsyncThunk(
  "seminarAlbum/getSeminarAlbum",
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiService.get(
        `/${SEMINAR_ALBUM_CONTROLLER}/${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteSeminarAlbum = createAsyncThunk(
  "seminarAlbum/deleteSeminarAlbum",
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiService.delete(
        `/${SEMINAR_ALBUM_CONTROLLER}/${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const seminarAlbumSlice = createSlice({
  name: "seminarAlbum",
  initialState: {
    seminarAlbums: [],
    selectedAlbum: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get Paginated Albums
      .addCase(getSeminarAlbumsWithPagination.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSeminarAlbumsWithPagination.fulfilled, (state, action) => {
        state.seminarAlbums = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getSeminarAlbumsWithPagination.rejected, (state, action) => {
        state.seminarAlbums = [];
        state.loading = false;
        state.error = action.payload;
      })

      // Save Album
      .addCase(saveSeminarAlbum.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveSeminarAlbum.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(saveSeminarAlbum.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Update Album
      .addCase(updateSeminarAlbum.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSeminarAlbum.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateSeminarAlbum.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Get Single Album
      .addCase(getSeminarAlbum.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSeminarAlbum.fulfilled, (state, action) => {
        state.selectedAlbum = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getSeminarAlbum.rejected, (state, action) => {
        state.selectedAlbum = null;
        state.loading = false;
        state.error = action.payload;
      })

      // Delete Album
      .addCase(deleteSeminarAlbum.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSeminarAlbum.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteSeminarAlbum.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default seminarAlbumSlice.reducer;
