import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import LoginForm from "../components/Auth/LoginForm";
import ErrorPage from "../pages/ErrorPage";
import PermissionDenied from "../pages/PermissionDenied";
import MainLayout from "../components/Layout/MainLayout";
import { useAuth } from "../components/Auth/AuthContext";

import CompanyList from "../pages/company/company-list/CompanyList";
import UserList from "../pages/users/user-list/UserList";
import FullScreenLoader from "../components/loader/FullScreenLoader";
import SeminarAlbumList from "../pages/seminar-albums/seminar-album-list/SeminarAlbumList";

const PrivateRoute = ({ element, requiredPermission }) => {
  const { isAuthenticated, user } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return element;
};

const AppRoutes = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  if (isAuthenticated === null) {
    return <FullScreenLoader />;
  }

  // Separate page builder route
  const pageBuilderRoute = {};

  const privateRoutes = [
    {
      path: "/company",
      element: <PrivateRoute element={<CompanyList />} />,
    },
    {
      path: "/users",
      element: <PrivateRoute element={<UserList />} />,
    },
    {
      path: "/seminars",
      element: <PrivateRoute element={<SeminarAlbumList />} />,
    },

    {
      path: "*",
      element: <PermissionDenied />,
    },
  ];

  return (
    <Routes>
      <Route
        path="/login"
        element={
          isAuthenticated ? (
            <Navigate to={location.state?.from?.pathname || "/"} replace />
          ) : (
            <LoginForm />
          )
        }
      />
      <Route path="/error" element={<ErrorPage />} />
      <Route path="/permission-denied" element={<PermissionDenied />} />

      {isAuthenticated && (
        <>
          {/* Main Layout */}
          <Route path="/" element={<MainLayout />}>
            {privateRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <PrivateRoute
                    element={route.element}
                    requiredPermission={route.permission}
                  />
                }
              />
            ))}
          </Route>
        </>
      )}

      <Route
        path="*"
        element={
          <Navigate to={isAuthenticated ? "/error" : "/login"} replace />
        }
      />
    </Routes>
  );
};

export default AppRoutes;
